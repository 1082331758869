<template>
	<div class="readme-article">
		<h1 id="课程预定1">
			四、课程预定
		</h1>
		<h2 id="课程预定2">1.课程预定</h2>
		<h3 id="课程预定列表">1.1课程预定列表</h3>
		<p>查看课程预定记录，包含学员姓名、预定时间、课时费、剩余课时等。</p>
		<p>
			<img src="@/assets/img/teach/4-1.png">
		</p>
		<h3 id="课程修改">1.2课程修改</h3>
		<p>展示课程信息，管理员可修改课时费标准。</p>
		<p>
			<img src="@/assets/img/teach/4-2.png">
		</p>
		<h3 id="课程调整">1.3课程调整</h3>
		<p>展示课程信息，管理员可修改课程。</p>
		<p>
			<img src="@/assets/img/teach/4-3.png">
		</p>
		<h3 id="课程分析">1.4课程分析</h3>
		<p>展示课程信息，管理员可分拆课程预定。</p>
		<p>
			<img src="@/assets/img/teach/4-4.png">
		</p>
		<h2 id="赠课-学币管理">2.赠课/学币管理</h2>
		<p>查看赠课/学币记录，显示学员姓名，剩余赠课/学币等操作，可选择多条记录进行合并。</p>
		<p>
			<img src="@/assets/img/teach/4-5.png">
		</p>
		<h2 id="课程预定日志">3.课程预定日志</h2>
		<p>查看课程预定日志记录。</p>
		<p>
			<img src="@/assets/img/teach/4-6.png">
		</p>
	</div>
</template>

<script>
	export default {
		name: "Staff5-1",
		data() {
			return {};
		},
		mounted() {},
		methods: {},
	};
</script>
<style scoped>
</style>
